// filterUtils.ts
export interface FilterCriteria {
    [key: string]: string;
}

export const filterData = (data: any[], criteria: FilterCriteria): any[] => {
    return data.filter(item => {
        return Object.keys(criteria).some(key => {
            const value = criteria[key].toLowerCase();
            return item[key] && item[key].toString().toLowerCase().includes(value);
        });
    });
};