import React from 'react';
import { useTranslation } from 'react-i18next';
import { PiBatteryVerticalEmptyBold, PiFileCsvBold } from 'react-icons/pi';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Define the types for the table columns and rows
export interface TableColumn {
    header: string;
    key: string;
    isEditable?: boolean;
}

export interface TableRow {
    [key: string]: string | number;
}

interface TableProps {
    table_name: string; // needed for export
    columns: TableColumn[];
    data: TableRow[];
    onRowClick?: (row: TableRow) => void;
    loading: boolean;
}

const Table: React.FC<TableProps> = ({
    table_name,
    columns,
    data,
    onRowClick,
    loading,
}) => {
    const { t } = useTranslation();

    // Function to export data as CSV
    const handleExportCSV = () => {
        const csvContent = `data:text/csv;charset=utf-8,${[
            columns.map(col => col.header).join(','),
            ...data.map(row => columns.map(col => row[col.key]).join(','))
        ].join('\n')}`;

        // Get current date and time
        const now = new Date();
        const timestamp = now.toISOString().replace(/T/, '_').replace(/:/g, '-').split('.')[0]; // Format: YYYY-MM-DD_HH-MM-SS

        // Create a file name with date and time
        const fileName = `${(table_name)}_${timestamp}.csv`;

        // Create a download link
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', fileName);
        document.body.appendChild(link); // Required for Firefox
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="relative rounded-2xl overflow-hidden">
            <div
                className="w-full overflow-x-auto"
                style={{ overflowY: 'auto' }} // Adjust the height as needed
            >
                <table className="font-body font-normal w-full text-sm text-left rtl:text-right text-txt-lg-subtitle dark:text-txt-dk-subtitle table-auto border-separate border-spacing-0">
                    <thead
                        className="font-body font-normal text-xs text-white uppercase bg-enerando-blue dark:bg-bg-dk-input dark:text-txt-dk-subtitle"
                        style={{ position: 'sticky', top: 0, zIndex: 1 }}
                    >
                        <tr>
                            {columns.map((column, index) => (
                                <th
                                    key={column.key}
                                    scope="col"
                                    className={`px-6 py-3 bg-enerando-blue dark:bg-bg-dk-input ${index === 0 ? 'rounded-tl-2xl' : ''}`}
                                >
                                    {loading ? <Skeleton width={100} /> : column.header}
                                </th>
                            ))}
                            {/* Extra column for CSV export */}
                            <th className="px-6 py-3 text-right bg-enerando-blue dark:bg-bg-dk-input rounded-tr-2xl">
                                <button onClick={handleExportCSV} title={t('Export to CSV')}>
                                    <PiFileCsvBold className="text-xl text-white" />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            Array.from({ length: 5 }).map((_, rowIndex) => (
                                <tr
                                    key={rowIndex}
                                    className="bg-white border-b border-border-lg dark:bg-bg-dk-content"
                                >
                                    {columns.map((column) => (
                                        <td key={column.key} className="px-6 py-4">
                                            <Skeleton />
                                        </td>
                                    ))}
                                    {/* Empty cell for the CSV button */}
                                    <td></td>
                                </tr>
                            ))
                        ) : data.length > 0 ? (
                            data.map((row, rowIndex) => (
                                <tr
                                    key={rowIndex}
                                    className="font-body font-normal bg-white border-b border-border-lg dark:bg-bg-dk-content hover:bg-hover-lg dark:hover:bg-hover-dk"
                                    onClick={() => onRowClick && onRowClick(row)}
                                    style={{ cursor: onRowClick ? 'pointer' : 'default' }}
                                >
                                    {columns.map((column) => (
                                        <td
                                            key={column.key}
                                            className="font-body px-6 py-4 font-normal text-txt-lg-body dark:text-txt-dk-body break-words"
                                        >
                                            {row[column.key]}
                                        </td>
                                    ))}
                                    {/* Empty cell for the CSV button */}
                                    <td></td>
                                </tr>
                            ))
                        ) : (
                            // Custom Empty State
                            <tr>
                                <td
                                    colSpan={columns.length + 1} // Include the extra column for CSV
                                    className="px-6 py-4 bg-white dark:bg-bg-dk-content"
                                >
                                    <div className="flex flex-col items-center justify-center py-10 bg-white dark:bg-bg-dk-content">
                                        <PiBatteryVerticalEmptyBold className="text-6xl text-enerando-red mb-4" />
                                        <p className="text-lg text-enerando-gray">
                                            {t('noting found ...')}
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Table;
