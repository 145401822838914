import { useState, useEffect, useCallback } from 'react';
import { getChargepointDetails } from '../services/apiService';
import { Components } from '../api/client';
import { UUID } from 'crypto';

type Chargepoint = Components.Schemas.Chargepoint;
type Session = Components.Schemas.Session;

// This as type import too?
export interface ChargepointProps {
    chargepoint: Chargepoint;
    chargings: Session[];
    charging_state: string | null;
}

const useChargepointDetails = (id: UUID) => {
    const [chargepointDetails, setChargepointDetails] = useState<ChargepointProps | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const fetchChargepointDetails = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await getChargepointDetails(id);

            // Adjust the energy field for each charging session
            const adjustedChargings = data.chargings.map((session: Session) => ({
                ...session,
                energy: session.energy / 1000,
            }));

            setChargepointDetails({
                chargepoint: data.chargepoint,
                chargings: adjustedChargings,
                charging_state: data.charging_state,
            });
        } catch (error) {
            setError('Failed to fetch chargepoint details');
        } finally {
            setLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchChargepointDetails();
    }, [fetchChargepointDetails]);

    return { chargepointDetails, error, loading, refetch: fetchChargepointDetails };;
};

export default useChargepointDetails;
