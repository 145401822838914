import React, { useState, useEffect, useRef } from 'react';
import { FaSearch, FaInfoCircle } from 'react-icons/fa';
import Spinner from './Spinner';
import { useTranslation } from 'react-i18next';

interface SearchInputProps {
  id: string;
  value: string;
  onChange: (value: string) => void;
  displayKey: string;
  description?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  id,
  value,
  onChange,
  displayKey,
  description = 'Used to search for any keyword of a table...',
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [isLoading, setIsLoading] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const { t } = useTranslation();
  const tooltipRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);
  const typingTimeoutRef = useRef<number | null>(null); // Ref to hold the timeout

  // Update the local input value when the prop changes
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // Debounce input change to delay filtering
  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    setIsLoading(true);

    typingTimeoutRef.current = window.setTimeout(() => {
      onChange(inputValue);
      setIsLoading(false);
    }, 500); // Delay of 500ms before firing the onChange event

    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, [inputValue, onChange]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  useEffect(() => {
    if (showDescription && tooltipRef.current && iconRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const iconRect = iconRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      let top = iconRect.bottom + 8; // 8px below the icon
      let left = iconRect.left + iconRect.width / 2 - tooltipRect.width / 2;

      if (left + tooltipRect.width > viewportWidth - 8) {
        left = viewportWidth - tooltipRect.width - 8;
      }

      if (left < 8) {
        left = 8;
      }

      if (top + tooltipRect.height > viewportHeight - 8) {
        top = iconRect.top - tooltipRect.height - 8;
      }

      if (top < 8) {
        top = 8;
      }

      tooltipRef.current.style.top = `${top}px`;
      tooltipRef.current.style.left = `${left}px`;
    }
  }, [showDescription]);

  return (
    <div className="relative z-20 w-full max-w-md lg:max-w-lg xl:max-w-xl mb-6 mx-auto">
      <div className="relative flex items-center">
        <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
          {isLoading ? (
            <Spinner size={16} />
          ) : (
            <FaSearch className="text-gray-500" />
          )}
        </div>
        <input
          type="text"
          id={id}
          value={inputValue}
          onChange={handleInputChange}
          required={false}
          className="font-body font-normal block w-full pl-10 pr-10 py-2.5 text-sm text-txt-lg-subtitle bg-white border-0 rounded-2xl appearance-none dark:text-txt-dk-subtitle dark:bg-bg-dk-input focus:outline-none focus:ring-0"
        />
        <div className="relative">
          <div
            ref={iconRef}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 hover:cursor-pointer"
            onMouseEnter={() => setShowDescription(true)}
            onMouseLeave={() => setShowDescription(false)}
            onClick={toggleDescription}
          >
            <FaInfoCircle className="text-gray-500" />
          </div>
          {showDescription && (
            <div
              ref={tooltipRef}
              className="fixed z-10 inline-block text-sm w-44 bg-bg-lg-item divide-y divide-bg-lg-content rounded-lg shadow dark:bg-bg-dk-item dark:divide-bg-dk-content p-3"
              style={{ top: '0px', left: '0px', whiteSpace: 'pre-wrap' }}
            >
              {t(description)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
