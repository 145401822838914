import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { calculateDuration } from '../utils/utils';
import { useTranslation } from 'react-i18next';
import useChargingSessions from '../hooks/useSessions';

// components
import Table, { TableColumn, TableRow } from '../components/basics/table';
import ButtonGroup from '../components/buttons/ButtonGroup';
import Heading from '../components/typography/Heading';
import ErrorPage from './ErrorPage';


const SessionsPage: React.FC = () => {
    const [viewCompleted, setViewCompleted] = useState(false); // initially shows ongoing sessions
    const { ongoingSessions, completedSessions, error, loading } = useChargingSessions();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const ongoingColumns: TableColumn[] = [
        { header: t("start time"), key: "start" },
        { header: t("duration"), key: "duration" },
        { header: t("charging station"), key: "charging_station" },
        { header: t("user"), key: "user" },
    ];

    const completedColumns: TableColumn[] = [
        { header: t("start time"), key: "start_date" },
        { header: t("duration"), key: "duration" },
        { header: t("energy") + " (kWh)", key: "energy_kwh" },
        { header: t("charging station"), key: "charging_station" },
        { header: t("user"), key: "user" },
    ];

    if (error) {
        return <ErrorPage errorCode={500} errorMessage={error} />;
    }

    const sessions = viewCompleted ? completedSessions : ongoingSessions;

    const tableData: TableRow[] = sessions.map(session => ({
        start_date: viewCompleted ? `${new Date(session.start).toLocaleString()}` : "",
        duration: viewCompleted ? calculateDuration(session.start, session.stop) : calculateDuration(session.start, new Date().toISOString()),
        energy_kwh: viewCompleted ? (session.energy / 1000).toFixed(2) : "",
        charging_station: session.chargepoint_name,
        start: new Date(session.start).toLocaleString(),
        uuid: session.uuid,
        user: session.user.first_name + " " + session.user.last_name + ", " + session.user.company,
    }));

    const handleRowClick = (row: TableRow) => {
        navigate(`/sessions/${row.uuid}`);
    };

    return (
        <div>
            <Heading
                title={t('sessions')}
                highlight={t('charging')}
                description={t('Current and historic session details.')}
            />
            <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">
                <div className="flex justify-left mb-4">
                    <ButtonGroup names={[t('Ongoing'), t('Completed')]} viewCompleted={viewCompleted} setViewCompleted={setViewCompleted} />
                </div>

                <Table table_name="sessions" columns={viewCompleted ? completedColumns : ongoingColumns} data={tableData} onRowClick={handleRowClick} loading={loading} />

            </div>
        </div>
    );
};

export default SessionsPage;
