import React, { useEffect, useState } from 'react';
import Button, { ButtonVariant } from '../buttons/Button';
import LinkButton from '../buttons/LinkButton';
import { Link } from 'react-router-dom';
import { isDarkMode } from '../../utils/utils';
import Spinner from '../Spinner';
import { Components } from '../../api/client';

type ChargepointStatus = Components.Schemas.Chargepoint["status"];

export interface CardProps {
    title?: string;
    description?: string;
    href?: string;
    buttonText?: string;
    buttonOnClick?: () => void;
    buttonVariant?: ButtonVariant;
    children?: React.ReactNode;
    isAuthenticated?: boolean;
    requireAuth?: boolean;
    loading?: boolean;
    exportButton?: React.ReactNode;
    fullWidth?: boolean;
    titleFontSize?: string;
    showStatusIcon?: boolean;
    status?: ChargepointStatus;
    alignment?: 'left' | 'center';
    hoverEffect?: 'default' | 'card';
}

const Card: React.FC<CardProps> = ({
    title,
    description,
    href,
    buttonText,
    buttonOnClick,
    buttonVariant,
    children,
    isAuthenticated,
    requireAuth,
    loading,
    exportButton,
    fullWidth = false,
    titleFontSize = 'text-base',
    status,
    alignment = 'left',
    hoverEffect = 'default'
}) => {
    const [darkMode, setDarkMode] = useState(isDarkMode());

    useEffect(() => {
        const handleDarkModeChange = (e: MediaQueryListEvent) => {
            setDarkMode(e.matches);
        };

        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        darkModeMediaQuery.addEventListener('change', handleDarkModeChange);

        return () => {
            darkModeMediaQuery.removeEventListener('change', handleDarkModeChange);
        };
    }, []);

    const titleStyle = titleFontSize ? { fontSize: titleFontSize } : {};
    const alignmentClass = alignment === 'center' ? 'text-center' : 'text-left';

    // Map status to label and color
    const statusMapping: Record<string, { label: string, color: string }> = {
        AVAILABLE: { label: 'Available', color: 'rgba(68, 214, 44, 1)' },
        CHARGING: { label: 'Charging', color: 'rgba(9, 170, 205, 1)' },
        BLOCKED: { label: 'Blocked', color: 'rgba(68, 214, 44, 1)' }, // Placeholder, can change later
        INOPERATIVE: { label: 'Inoperative', color: 'rgba(68, 214, 44, 1)' }, // Placeholder, can change later
        OUTOFORDER: { label: 'Out of Order', color: 'rgba(68, 214, 44, 1)' }, // Placeholder, can change later
        PLANNED: { label: 'Planned', color: 'rgba(68, 214, 44, 1)' }, // Placeholder, can change later
        REMOVED: { label: 'Removed', color: 'rgba(68, 214, 44, 1)' }, // Placeholder, can change later
        RESERVED: { label: 'Reserved', color: 'rgba(68, 214, 44, 1)' }, // Placeholder, can change later
        UNKNOWN: { label: 'Unknown', color: 'rgba(68, 214, 44, 1)' }, // Placeholder, can change later
    };

    const currentStatus = statusMapping[status ?? 'UNKNOWN'];
    const statusLabel = currentStatus.label;
    const statusColor = currentStatus.color;

    const darkModeClass = darkMode ? 'dark' : '';

    const cardContent = (
        <div className="flex flex-col flex-grow w-full card-content relative">
            <div className={`flex flex-col flex-grow ${alignmentClass}`}>
                <div className={`flex ${alignment === 'center' ? 'justify-center' : 'justify-between'} w-full`}>
                    {loading ? (
                        <div className="flex justify-center items-center h-full">
                            <Spinner size={32} />
                        </div>
                    ) : (
                        href && hoverEffect !== 'card' ? (
                            <Link to={href}>
                                <h5 className={`mb-2 font-body font-semibold tracking-tight text-txt-lg-header hover:text-enerando-green dark:text-txt-dk-header ${alignmentClass}`} style={titleStyle}>
                                    {title}
                                </h5>
                            </Link>
                        ) : (
                            <h5 className={`mb-2 font-body font-semibold tracking-tight text-txt-lg-header dark:text-txt-dk-header ${alignmentClass}`} style={titleStyle}>
                                {title}
                            </h5>
                        )
                    )}
                    {exportButton && (
                        <div className="ml-2">
                            {exportButton}
                        </div>
                    )}
                </div>
                {loading ? (
                    <div className="flex justify-center items-center h-full">
                        <Spinner size={32} />
                    </div>
                ) : (
                    <p className={`mb-3 font-body text-txt-lg-body dark:text-txt-dk-body text-sm ${alignmentClass}`}>{description}</p>
                )}
                {requireAuth && !isAuthenticated ? (
                    <p className={`mb-3 font-body text-txt-lg-body dark:text-txt-dk-body text-sm ${alignmentClass}`}>You need to log in to see this content.</p>
                ) : (
                    <div className="flex-grow">{children}</div>
                )}
            </div>
            {buttonText && (
                <div className="mt-4 flex justify-end">
                    {loading ? (
                        <div className="flex justify-center items-center h-full">
                            <Spinner size={32} />
                        </div>
                    ) : (
                        href && hoverEffect !== 'card' ? (
                            <LinkButton to={href} text={buttonText} variant={buttonVariant} />
                        ) : (
                            <Button text={buttonText} onClick={buttonOnClick} variant={buttonVariant} />
                        )
                    )}
                </div>
            )}
        </div>
    );

    const statusAnimations: Record<string, string> = {
        AVAILABLE: 'static-outline',
        CHARGING: 'pulsating-outline',
        BLOCKED: 'static-outline', // Placeholder, can change later
        INOPERATIVE: 'static-outline', // Placeholder, can change later
        OUTOFORDER: 'static-outline', // Placeholder, can change later
        PLANNED: 'static-outline', // Placeholder, can change later
        REMOVED: 'static-outline', // Placeholder, can change later
        RESERVED: 'static-outline', // Placeholder, can change later
        UNKNOWN: 'static-outline', // Placeholder, can change later
    };

    // Get the animation based on the status or default to AVAILABLE style
    const statusAnimation = statusAnimations[status ?? 'UNKNOWN'];

    const renderCard = () => (
        <div
            className={`p-6 rounded-2xl flex flex-col justify-between h-full w-full ${fullWidth ? 'w-full' : 'max-w-md'} hover-effect-card card-container ${statusAnimation} hover:bg-hover ${darkModeClass} relative ${hoverEffect === 'card'}`}
            style={{ backgroundColor: hoverEffect === 'card' ? undefined : (darkMode ? 'var(--bg-dk-bg)' : 'var(--bg-lg-bg)') }} // Removed boxShadow condition
        >
            <div className="status-label" style={{ color: statusColor }}>{statusLabel}</div>
            {cardContent}
        </div>
    );
    if (hoverEffect === 'card') {
        return href ? (
            <Link to={href} className="block h-full w-full">
                {renderCard()}
            </Link>
        ) : (
            renderCard()
        );
    }

    return (
        <div className={`p-6 bg-bg-lg-content rounded-2xl dark:bg-bg-dk-content flex flex-col justify-between h-full w-full ${fullWidth ? 'w-full' : 'max-w-md'} shadow-sm`}>
            {cardContent}
        </div>
    );
};

export default Card;
