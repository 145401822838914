import React, { useState } from 'react';
import { BsFillLightningChargeFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { Components } from '../api/client';
import { formatDate } from '../utils/utils';

type Session = Components.Schemas.Session;

interface TimelineProps {
  sessions: Session[];
}

const Timeline: React.FC<TimelineProps> = ({ sessions }) => {
  const [showAll, setShowAll] = useState(false);

  const displayedSessions = showAll ? sessions : sessions.slice(0, 10);

  return (
    <div className="mx-6 flex flex-col items-center">
      <ol className="relative border-l border-gray-200 dark:border-gray-700">
        {displayedSessions.map((session) => (
          <li key={session.uuid} className="mb-5 ml-6 relative flex items-center">
            <Link to={`/sessions/${session.uuid}`} className="group flex items-center">
              <div className="absolute -left-10 flex items-center justify-center w-7 h-7 rounded-full ring-8 ring-blue-100 group-hover:ring-hover-lg dark:ring-gray-900 dark:group-hover:ring-hover-dk bg-enerando-blue group-hover:bg-opacity-40 transition duration-150">
                <BsFillLightningChargeFill className="w-5 h-5 text-enerando-green group-hover:text-opacity-40 transition duration-150" />
              </div>
              <div className="ml-4 px-4 py-2 rounded-lg transition duration-150 group-hover:bg-opacity-40 dark:group-hover:bg-opacity-40">
                <h3 className="text-lg font-body font-semibold text-txt-lg-body dark:text-txt-dk-body group-hover:text-opacity-40">
                  User: Missing Data - {session.status}
                </h3>
                <time className="block text-sm font-body font-normal leading-none text-gray-400 dark:text-gray-500 group-hover:text-opacity-40">
                  {/* TODO: change state to status field from charging */}
                  {session.state === 'charging' ? `Started at: ${formatDate(session.start)}` : `${formatDate(session.start)} - ${formatDate(session.stop)}`}
                </time>
                <p className="text-base font-body font-normal text-gray-500 dark:text-gray-400 group-hover:text-opacity-40">
                  {session.energy} kWh charged
                </p>
              </div>
            </Link>
          </li>
        ))}
      </ol>
      {sessions.length > 10 && (
        <button
          onClick={() => setShowAll(!showAll)}
          className="mt-4 text-blue-700 hover:underline"
        >
          {showAll ? 'Show Less' : 'Show More'}
        </button>
      )}
    </div>
  );
};

export default Timeline;
