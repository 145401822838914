import { useState } from 'react';
import { postSiteUpdate } from '../services/apiService';
import { Paths } from '../api/client';
import { UUID } from 'crypto';

type SiteUpdateResponse = Paths.SitesUpdateCreate.Responses.$200;

const useSiteUpdate = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);

    const updateSite = async (id: UUID): Promise<SiteUpdateResponse | null> => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const data: SiteUpdateResponse = await postSiteUpdate(id);
            setSuccess(true);
            return data;
        } catch (error) {
            setError('Failed to update site.');
            return null;
        } finally {
            setLoading(false);
        }
    };

    return { updateSite, loading, error, success };
};

export default useSiteUpdate;