import React, { useState, FormEvent } from 'react';
import { useAuth } from '../contexts/AuthContext';
import Card from './basics/Card';
import Input from './basics/Input';
import Button, { ButtonVariant } from './buttons/Button';
import Spinner from './Spinner';
import { useTranslation } from 'react-i18next';

interface LoginFormProps {
    onLoginSuccess?: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLoginSuccess }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { login } = useAuth();
    const { t } = useTranslation();

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setLoading(true);
        try {
            const success = await login(username, password);
            if (!success) {
                setError(t('Login failed. Please check your credentials.'));
            } else if (onLoginSuccess) {
                onLoginSuccess();
            }
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError(t('An unexpected error occurred.'));
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="relative">
            {loading && (
                <div className="overlay">
                    <Spinner size={64} />
                </div>
            )}
            <div className={loading ? 'blur' : ''}>
                <Card title="Login" description={t("Please enter your username and password to log in.")}>
                    <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">
                        <form onSubmit={handleSubmit} className="w-full flex flex-col items-center">
                            <div className="w-full max-w-sm">
                                <Input
                                    id={t("username")}
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                    label={t("Username")}
                                />
                            </div>
                            <div className="w-full max-w-sm mt-2">
                                <Input
                                    id={t("password")}
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    label={t("Password")}
                                />
                            </div>
                            {error && <div className="mb-6 text-red-600">{error}</div>}
                            <div className="w-full max-w-sm mt-4 flex justify-end">
                                <Button text={t("Submit")} variant={ButtonVariant.Default} type="submit" />
                            </div>
                        </form>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default LoginForm;
