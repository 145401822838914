import React from 'react';

type ToggleButtonProps = {
    checked: boolean;
    onChange: (checked: boolean) => void;
    loading?: boolean;
    label?: string;
    disabled?: boolean;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({checked, onChange, label, disabled, loading = false}) => {
    return (
        <label className={`inline-flex items-center cursor-pointer ${disabled || loading ? 'cursor-not-allowed' : ''}`}>
            <input
                type="checkbox"
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
                className="sr-only peer"
                disabled={disabled || loading}
            />
            <div
                className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 
                peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white 
                after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border 
                after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-enerando-green`}
            >
                {loading && <div className="absolute inset-0 flex justify-center items-center"><span className="loader"></span></div>}
            </div>
            {label && (
                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    {label}
                </span>
            )}
        </label>
    );
};

export default ToggleButton;