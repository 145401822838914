import React from 'react';
import Spinner from '../Spinner';
import Card, { CardProps } from '../basics/Card';
import AttributeCard from '../basics/AttributeCard';

export enum CardType {
    Default = 'default',
    Attribute = 'attribute',
    Grid = 'grid',
}

export enum Alignment {
    Left = 'left',
    Center = 'center',
}

export enum HoverEffect {
    Default = 'default',
    Card = 'card',
}

export interface CardItem extends Omit<CardProps, 'loading' | 'titleFontSize'> {
    type: CardType;
    value?: string; // For attribute card
    name?: string; // For attribute card
    icon?: React.ReactNode; // For attribute card
    items?: CardItem[]; // For nested grid
    link?: string;
    loading: boolean; // Override loading prop
    titleFontSize?: string; // Add back titleFontSize
    alignment?: Alignment; // Add alignment prop
    hoverEffect?: HoverEffect;
    fullWidth?: boolean;
}

interface CardGridProps {
    items: CardItem[];
    loading?: boolean;
}

const CardGrid: React.FC<CardGridProps> = ({ items, loading }) => {
    if (loading) {
        return (
            <Card alignment='center' fullWidth={true}>
                <div className="flex justify-center items-center h-64 w-full">
                    <Spinner size={64} />
                </div>
            </Card>
        );
    }

    return (
        <div className="w-full">
            <div className="grid gap-5" style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))' }}>
                {items.map((item, index) => {
                    if (item.loading) {
                        return (
                            <div key={index} className="flex justify-center items-center h-48 w-full">
                                <Spinner size={64} />
                            </div>
                        );
                    }

                    switch (item.type) {
                        case CardType.Default:
                            return (
                                <div key={index} className="flex h-full w-full">
                                    <Card
                                        title={item.title!}
                                        description={item.description!}
                                        href={item.href}
                                        buttonText={item.buttonText}
                                        buttonOnClick={item.buttonOnClick}
                                        buttonVariant={item.buttonVariant}
                                        isAuthenticated={item.isAuthenticated}
                                        requireAuth={item.requireAuth}
                                        loading={item.loading}
                                        titleFontSize={item.titleFontSize}
                                        status={item.status}
                                        alignment={item.alignment}
                                        hoverEffect={item.hoverEffect}
                                        fullWidth={item.fullWidth}
                                    >
                                        {item.children}
                                    </Card>
                                </div>
                            );
                        case CardType.Attribute:
                            return (
                                <div key={index} className="flex h-full w-full">
                                    <AttributeCard
                                        value={item.value!}
                                        name={item.name!}
                                        icon={item.icon}
                                        loading={item.loading}
                                    />
                                </div>
                            );
                        case CardType.Grid:
                            return (
                                <div key={index} className="w-full">
                                    <CardGrid items={item.items!} />
                                </div>
                            );
                        default:
                            return null;
                    }
                })}
            </div>
        </div>
    );
};

export default CardGrid;
