import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdHomeFilled } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const Breadcrumb: React.FC = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const { t } = useTranslation();

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
        <li className="inline-flex items-center">
          <Link to="/" className="inline-flex items-center text-sm font-body font-normal text-txt-lg-placeholder hover:text-enerando-green dark:text-txt-dk-placeholder dark:hover:text-white">
            <MdHomeFilled className="w-4 h-4 me-2.5" aria-hidden="true" />
            {t("Home")}
          </Link>
        </li>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;

          return (
            <li key={to} className="flex items-center">
              <svg className="rtl:rotate-180 w-3 h-3 text-txt-lg-placeholder mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
              </svg>
              {index === pathnames.length - 1 ? (
                <span className="ms-1 text-sm font-body font-normal text-txt-lg-placeholder md:ms-2 dark:text-gray-400">{t(value)}</span>
              ) : (
                <Link to={to} className="ms-1 text-sm font-body font-normal text-txt-lg-placeholder hover:text-enerando-green md:ms-2 dark:text-gray-400 dark:hover:text-white">
                  {t(value)}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
