import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Hooks & utils
import useChargingDetails from '../hooks/useSessionDetails';
import useDarkMode from '../hooks/useDarkMode';
import { calculateDuration } from '../utils/utils';

// Components
import Heading from '../components/typography/Heading';
import CardGrid, { CardItem, Alignment, CardType } from '../components/positioning/CardGrid';
import StatusIndicator from '../components/StatusIndicator';
import SessionTime from '../components/SessionTime';
import LeafletMap from '../components/Map';
import Card from '../components/basics/Card';
import ThisJSONTree from '../components/JSONTree';
import CollapsedContent from '../components/CollapsedContent';
import XmlExportButton from '../components/buttons/XmlExportButton';
import PdfExportButton from '../components/buttons/PdfExportButton';
import Receipt from '../components/Receipt';
import Spinner from '../components/Spinner';
import ErrorPage from './ErrorPage';

// Icons
import { BsFillLightningChargeFill } from "react-icons/bs";
import { FaMoneyBills, FaLocationDot } from "react-icons/fa6";
import { IoTimer } from "react-icons/io5";
import { UUID } from 'crypto';

const SessionDetailsPage: React.FC = () => {
    const { id } = useParams<{ id: UUID }>();
    const { sessionDetails, cdrDetails, error, loading } = useChargingDetails(id!);
    const [duration, setDuration] = useState<string>("00:00:00");
    const { t } = useTranslation();
    const isDarkMode = useDarkMode(); // For JSON tree

    useEffect(() => {
        if (sessionDetails && sessionDetails.start) {
            const startTime = sessionDetails.start;
            let stopTime = sessionDetails.state === 'charging' ? new Date().toISOString() : sessionDetails.stop || new Date().toISOString();

            setDuration(calculateDuration(startTime, stopTime));

            if (sessionDetails.state === 'charging') {
                const intervalId = setInterval(() => {
                    stopTime = new Date().toISOString();
                    setDuration(calculateDuration(startTime, stopTime));
                }, 1000);

                return () => clearInterval(intervalId);
            }
        }
    }, [sessionDetails]);

    if (error) {
        return <ErrorPage errorCode={500} errorMessage={error} />;
    }

    const energy = sessionDetails?.energy ?? 0;
    const tariff = cdrDetails?.cdr_energy_tariff ?? 0;
    const net = tariff * energy;
    const vatRate = 20; // Define VAT rate
    const vat = net * (vatRate / 100);
    const totalCost = net + vat;

    // Safely handle potential null or undefined values
    const locations = sessionDetails && sessionDetails.loc && sessionDetails.address ? [
        {
            id: 1,
            name: sessionDetails.address,
            position: [sessionDetails.loc[1], sessionDetails.loc[0]] as [number, number]
        }
    ] : [];

    const center: [number, number] = sessionDetails && sessionDetails.loc ? [
        sessionDetails.loc[1] ?? 48.1351,
        sessionDetails.loc[0] ?? 11.5820
    ] : [48.1351, 11.5820];

    const attributeItems: CardItem[] = [
        {
            type: CardType.Default,
            fullWidth: true,
            alignment: Alignment.Center,
            children: (
                <SessionTime
                    startTime={sessionDetails?.start ? new Date(sessionDetails.start) : undefined}
                    endTime={sessionDetails?.stop ? new Date(sessionDetails.stop) : undefined}
                    status={sessionDetails?.status ?? ''}
                    loading={loading}
                />
            ),
            loading: loading
        },
        {
            type: CardType.Default,
            fullWidth: true,
            alignment: Alignment.Center,
            children: (
                <StatusIndicator
                    status={sessionDetails?.status ?? ''}
                    loading={loading}
                />
            ),
            loading: loading
        },
        {
            type: CardType.Attribute,
            value: sessionDetails?.address || t('No address available'),
            name: t('location'),
            icon: <FaLocationDot className="mb-2 text-2xl text-enerando-green" />,
            loading: loading,
        },
        {
            type: CardType.Attribute,
            value: sessionDetails?.energy != null ? sessionDetails.energy.toString() : t('N/A'),
            name: `${t('charged')} ${t('energy')} (kWh)`,
            icon: <BsFillLightningChargeFill className="w-5 h-5 text-enerando-green" />,
            loading: loading,
        },
        {
            type: CardType.Attribute,
            value: duration,
            name: t('duration'),
            icon: <IoTimer className="w-5 h-5 text-enerando-green" />,
            loading: loading,
        },
        {
            type: CardType.Attribute,
            value: sessionDetails?.cost != null ? `${totalCost.toFixed(2)} €` : t('N/A'),
            name: t('revenue'),
            icon: <FaMoneyBills className="w-5 h-5 text-enerando-green" />,
            loading: loading,
        },
    ];

    const parseCdrSignedValue = (cdrSignedValue: string) => {
        try {
            const parts = cdrSignedValue.split('|');
            if (parts.length === 3) {
                const jsonPart = JSON.parse(parts[1]);
                return jsonPart;
            } else {
                console.error('Invalid CDR signed value parts:', parts);
            }
            return null;
        } catch (e) {
            console.error('Failed to parse CDR signed value:', e);
            return null;
        }
    };
    // cdr_signed_value is always null?
    const signedValJson = cdrDetails?.cdr_signed_value ? parseCdrSignedValue(cdrDetails.cdr_signed_value) : null;
    const pubKeyJson = cdrDetails?.cdr_public_key || null;

    const receiptData = {
        transactionId: sessionDetails?.uuid ?? '',
        period: `${sessionDetails?.start ?? ''} - ${sessionDetails?.stop ?? ''}`,
        energy,
        time: duration,
        tariff,
        totalCost
    }


    return (
        <div>
            <Heading
                title={t('session')}
                highlight={t('charging')}
                description=""
            />

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <Spinner size={32} />
                </div>

            ) : (
                <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">
                    <div className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-8 min-h-full">
                        <div className="w-full md:w-1/2 flex flex-col space-y-4 flex-1">
                            <CardGrid items={attributeItems} loading={loading} />
                        </div>
                        <div className="w-full md:w-1/2 flex-1">
                            <LeafletMap locations={locations} center={center} loading={loading} />
                        </div>
                    </div>

                    <div className="mt-10 flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-8 min-h-full">
                        {signedValJson && pubKeyJson && (
                            <>
                                <div className="w-full md:w-1/2 flex flex-col space-y-4 flex-1">
                                    <Card
                                        title={t('Receipt')}
                                        loading={loading}
                                        fullWidth={true}
                                        exportButton={
                                            <PdfExportButton
                                                filename="Receipt.pdf"
                                                data={receiptData}
                                            />
                                        }
                                    >
                                        <Receipt
                                            transactionId={receiptData.transactionId}
                                            period={receiptData.period}
                                            energy={receiptData.energy}
                                            time={receiptData.time}
                                            tariff={receiptData.tariff}
                                            totalCost={receiptData.totalCost}
                                        />
                                    </Card>
                                </div>
                                <div className="w-full md:w-1/2 flex flex-col space-y-4 flex-1">
                                    <Card
                                        title={t('Transparency')}
                                        loading={loading}
                                        fullWidth={true}
                                        exportButton={
                                            id && cdrDetails?.cdr_signed_value && cdrDetails?.cdr_public_key && (
                                                <XmlExportButton
                                                    filename={`Enerando_session_${id}.xml`}
                                                    data={{
                                                        transactionId: id,
                                                        cdrSignedValue: cdrDetails.cdr_signed_value,
                                                        cdrPublicKey: cdrDetails.cdr_public_key
                                                    }}
                                                />
                                            )
                                        }
                                    >
                                        <CollapsedContent title={t('Signed Meter Data')}>
                                            <ThisJSONTree data={signedValJson} invertTheme={!isDarkMode} />
                                        </CollapsedContent>
                                        <CollapsedContent title={t('Public Key')}>
                                            <ThisJSONTree data={pubKeyJson} invertTheme={!isDarkMode} />
                                        </CollapsedContent>
                                    </Card>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SessionDetailsPage;
