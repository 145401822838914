import { useState, useEffect } from 'react';
import { getCardDetails } from '../services/apiService';
import { Components } from '../api/client';
import { UUID } from 'crypto';

type Card = Components.Schemas.Card;
type Session = Components.Schemas.Session;

const useCardDetails = (id: UUID) => {
    const [cardDetails, setCardDetails] = useState<Card>();
    const [sessions, setSessions] = useState<Session[]>();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getCardDetails(id);
                setCardDetails(data.card);
                setSessions(data.sessions);
            } catch (error) {
                setError('Failed to fetch card details.');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);

    return { cardDetails, sessions, error, loading }
};

export default useCardDetails;