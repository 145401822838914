// Infrastructure.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSites from '../hooks/useSites';
import { castToUUID } from '../utils/utils';


// components
import Heading from '../components/typography/Heading';
import Container from '../components/basics/Container';
import SiteComponent from '../components/SiteComponent';
import ErrorPage from './ErrorPage';


const InfrastructurePage: React.FC = () => {
  const { t } = useTranslation();
  const { sites, error, } = useSites(); // no usage of loading here (maybe check later)

  if (error) {
    return <ErrorPage errorCode={500} errorMessage={error} />;
  }


  return (
    <div>
      <Heading
        title={t('infrastructure')}
        highlight=''
        description={t('All your charging stations at a glance.')}
      />
      <div className='main-content mt-10 text-txt-lg-body dark:text-txt-dk-body'>
        <div className="space-y-6">
          {sites.map((site) => {
            // check if valid UUID format since openAPI reads uuid as string (typescript error otherwise)
            const siteUUID = site?.uuid ? castToUUID(site.uuid) : null;
            return siteUUID ? (
              <Container key={siteUUID} title={`Site: ${site.name}`} titlePosition="left" bgColor='color2'>
                <SiteComponent siteId={siteUUID} />
              </Container>
            ) : null;
          })}
        </div>
      </div>
    </div>
  );
};

export default InfrastructurePage;
