import React from 'react';
import styles from './Logo.module.css';

const Logo: React.FC = () => (

    <svg
        width="100%"
        height="100%"
        viewBox="0 0 2240 425"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2,
        }}
    >
        <defs>
            <mask id="reveal-mask">
                <rect
                    id="mask-rect"
                    x="0"
                    y="0"
                    width="0"
                    height="100%"
                    fill="#ffffff"
                    style={{
                        animation: 'revealLogo 1s forwards',
                    }}
                />
            </mask>
            <style>
                {`
                    @keyframes revealLogo {
                        to {
                        width: 100%;
                        }
                    }
                `}
            </style>
        </defs>
        <g mask="url(#reveal-mask)">
            <g transform="matrix(1,0,0,1,-233.055,-147.129)">
                <g transform="matrix(4.16667,0,0,4.16667,0,0)">
                    {/* primary color */}
                    <path d="M293.099 69.084h-7.55v38.577h7.55v-27.61l22.43 27.61h7.55V69.084h-7.55v27.665l-22.43-27.665Zm105.095 23.201c6.889-1.102 9.81-4.629 9.81-11.573 0-8.046-3.638-11.628-12.069-11.628h-23.147v38.577h7.55V93.057h9.094l11.958 14.604h9.645l-12.841-15.376Zm-3.362-16.478c3.913 0 5.622 1.544 5.622 5.291 0 3.692-1.709 5.235-5.622 5.235h-14.494V75.807h14.494Zm55.717 8.543c-2.095-11.849-6.283-16.148-15.927-16.148-9.644 0-13.833 4.299-15.927 16.148l-4.133 23.311h7.55l1.929-10.857h21.162l1.929 10.857h7.55l-4.133-23.311Zm-24.359.275c1.212-7.109 3.472-9.589 8.432-9.589 4.96 0 7.219 2.535 8.432 9.589l.937 5.346h-18.738l.937-5.346Zm42.325-15.541h-7.55v38.577h7.55v-27.61l22.429 27.61h7.551V69.084h-7.551v27.665l-22.429-27.665Zm39.073 0v38.577h18.462c13.336 0 19.619-6.172 19.619-19.288 0-13.117-6.283-19.289-19.619-19.289h-18.462Zm7.55 6.834h10.912c8.597 0 12.069 3.637 12.069 12.455 0 8.817-3.472 12.454-12.069 12.454h-10.912V75.918Zm56.433 32.625c13.722 0 20.721-6.834 20.721-20.17 0-13.337-6.999-20.171-20.721-20.171-13.723 0-20.722 6.834-20.722 20.171 0 13.336 6.999 20.17 20.722 20.17Zm0-6.834c-8.928 0-13.172-4.243-13.172-13.336 0-9.094 4.244-13.337 13.172-13.337 8.927 0 13.171 4.243 13.171 13.337 0 9.093-4.244 13.336-13.171 13.336ZM332.178 75.808h33.209v-6.723h-33.209M332.206 91.183h31.528V84.46h-31.528M332.178 107.661h33.209v-6.723h-33.209"
                        className={styles.logoFillPrimary}
                        fillRule="nonzero"
                    />
                    {/* secondary color */}
                    <path d="M210.798 97.149c-4.413 1.055-7.077 1.693-9.989 2.172-3.017.497-5.886.8-8.649.968-1.535.983-2.994 1.958-4.534 2.99-1.095.733-2.234 1.497-3.481 2.315-3.251 2.135-4.755 3.2-6.659 4.546-1.223.867-2.606 1.845-4.751 3.315-5.646 3.871-11.484 7.873-18.562 10.987-4.666 2.053-11.717 5.155-21.173 5.584-12.274.56-21.329-3.576-24.739-5.132-6.531-2.978-11.065-6.563-17.34-11.525-1.584-1.253-5.853-4.735-11.255-9.983-.445-.432-.909-.904-1.372-1.374-11.436-5.796-19.621-13.451-21.208-14.993-.346-.3-.618-.657-.81-1.052.113.31.258.613.469.889 7.121 9.341 13.091 16.32 18.253 21.335 5.622 5.463 10.102 9.116 11.768 10.434 6.394 5.056 11.445 9.05 18.715 12.366 3.238 1.476 12.685 5.785 25.319 5.785.822 0 1.656-.018 2.503-.057 10.706-.485 18.446-3.89 23.569-6.144 7.651-3.366 13.752-7.549 19.658-11.599 2.175-1.49 3.581-2.485 4.826-3.366 1.85-1.31 3.312-2.344 6.466-4.414 1.266-.831 2.423-1.606 3.534-2.352 3.593-2.408 6.695-4.487 11.538-7.116 6.998-3.797 10.097-4.825 11.586-5.319.347-.115.63-.211.867-.299.224-.085.453-.159.678-.241-1.257.318-2.624.658-4.122 1.016l-1.105.264Z"
                        className={styles.logoFillSecondary}
                        fillRule="nonzero"
                    />
                    {/* accent color */}
                    <path d="M56.068 85.045c0-.011-.003-.022-.003-.033-.131-.984.16-2.014.907-2.78.639-.656 1.482-.992 2.331-1.01 3.894-2.492 7.555-4.415 10.92-5.9 4.463-5.338 8.662-9.429 11.618-12.309 2.931-2.855 5.754-5.334 8.63-7.579 5.769-4.502 10.751-8.39 18.349-10.981 2.675-.911 7.643-2.601 14.469-2.428 2.198.059 8.046.51 14.841 3.422 5.356 2.296 8.728 5.015 14.855 9.955 1.865 1.503 2.656 2.232 3.967 3.44 1.335 1.23 3.163 2.915 7.019 6.257 1.848 1.601 3.44 2.98 4.963 4.274 2.774-.815 5.544-1.421 8.305-1.816-2.858-2.304-5.42-4.522-8.88-7.521-3.779-3.275-5.564-4.92-6.866-6.12-1.364-1.257-2.265-2.088-4.308-3.735-6.368-5.134-10.212-8.233-16.415-10.892-7.859-3.368-14.718-3.893-17.303-3.962-8.025-.216-13.954 1.812-16.808 2.784-8.669 2.956-14.324 7.369-20.311 12.042-3.071 2.396-6.074 5.033-9.182 8.061-4.938 4.81-13.202 12.861-20.703 24.472-.659 1.021-.682 2.262-.187 3.281-.078-.214-.133-.431-.166-.653-.02-.089-.029-.179-.042-.269Z"
                        className={styles.logoFillAccent}
                        fillRule="nonzero"
                    />
                    <path d="m56.068 85.045-.003-.033c0 .011.003.022.003.033ZM224.324 86.61c-.21-.082-.421-.164-.634-.249-8.017.436-14.288-1.377-18.539-2.609l-.262-.077c-6.022-1.743-10.437-4.186-12.559-5.36-2.634-1.457-4.728-2.851-7.128-4.617-3.234-.019-6.499.299-9.786.959.145.112.281.221.429.335 5.275 4.078 8.459 6.539 13.241 9.185 2.153 1.192 7.196 3.981 13.939 5.934l.261.076c1.049.304 2.265.653 3.617 1.002.727-.172 1.491-.355 2.337-.557l1.106-.265c1.354-.323 2.591-.628 3.733-.917 2.995-.755 5.27-1.379 6.792-1.797l1.112-.303c.855-.229 1.722-.439 2.595-.64-.084-.032-.17-.067-.254-.1ZM57.086 87.019c-.015-.015-.038-.036-.052-.05-.481-.468-.785-1.046-.924-1.655.033.222.088.439.166.653.192.395.464.752.81 1.052Z"
                        className={styles.logoFill}
                        fillRule="nonzero"
                    />
                    {/* light color */}
                    <path d="M125.443 73.047c-5.034-1.539-14.399-4.409-26.919-4.054-4.364.121-8.999.671-13.405 1.589-3.8.792-8.879 2.085-14.896 4.74-3.365 1.485-7.026 3.408-10.92 5.9.865-.017 1.736.296 2.405.948.318.309.989.948 1.951 1.802.476.423 1.025.899 1.643 1.419 8.867-5.245 16.223-7.216 21.184-8.25 4.024-.839 8.251-1.34 12.225-1.451 11.427-.318 20.105 2.337 24.773 3.764 3.673 1.124 7.729 2.862 15.732 6.298.579-.452 1.119-.867 1.62-1.235.982-.72 2.679-1.95 4.641-3.31-1.133-.52-2.261-1.029-3.402-1.518-8.373-3.597-12.577-5.401-16.632-6.642ZM228.976 88.286c-.643-.21-1.309-.44-1.988-.681-.779-.277-1.58-.573-2.41-.895-.873.201-1.74.411-2.595.64l-1.112.303c-1.522.418-3.797 1.042-6.792 1.797-1.142.289-2.379.594-3.733.917l-1.106.265c-.846.202-1.61.385-2.337.557-2.941.698-5.038 1.168-7.183 1.521-20.847 3.435-34.428-2.192-47.186-8.188-2.404 1.581-4.797 3.273-6.368 4.404.349.166.684.321 1.038.488 11.056 5.238 23.295 11.037 40.273 11.036 1.518 0 3.087-.064 4.683-.161 2.763-.168 5.632-.471 8.649-.968 2.912-.479 5.576-1.117 9.989-2.172l1.105-.264c1.498-.358 2.865-.698 4.122-1.016 2.948-.748 5.216-1.369 6.62-1.755l1.075-.294c7.162-1.918 15.022-2.832 24.048-2.662.133.001.26-.021.39-.035-5.368.043-10.788-.097-19.182-2.837Z"
                        className={styles.logoFillLight}
                        fillRule="nonzero"
                    />
                    {/* accent color */}
                    <path d="M56.11 85.314c-.013-.09-.036-.179-.042-.269.013.09.022.18.042.269Z"
                        className={styles.logoFillAccent}
                        fillRule="nonzero"
                    />
                    {/* primary color */}
                    <path d="M249.346 84.431c-8.72 0-16.249.181-37.746-11.144-6.949-3.66-15.63-5.879-24.444-6.248-3.289-.137-6.597.043-9.917.518-2.761.395-5.531 1.001-8.305 1.816-5.467 1.608-10.951 4.021-16.419 7.251-2.261 1.336-4.819 3.043-7.038 4.583-1.962 1.36-3.659 2.59-4.641 3.31-.501.368-1.041.783-1.62 1.235-.465.364-.953.75-1.477 1.166-6.298 4.989-16.839 13.342-29.853 14.893-17.992 2.146-35.185-10.193-42.584-16.42-.618-.52-1.167-.996-1.643-1.419-.962-.854-1.633-1.493-1.951-1.802-.669-.652-1.54-.965-2.405-.948-.849.018-1.692.354-2.331 1.01-.747.766-1.038 1.796-.907 2.78l.003.033c.006.09.029.179.042.269.139.609.443 1.187.924 1.655.014.014.037.035.052.05 1.587 1.542 9.772 9.197 21.208 14.993 7.423 3.763 16.209 6.742 25.455 6.742 1.631 0 3.276-.093 4.93-.29 14.897-1.776 26.367-10.864 33.221-16.295 1.124-.891 2.095-1.659 2.898-2.249.314-.23.787-.575 1.368-.994 1.571-1.131 3.964-2.823 6.368-4.404 1.16-.762 2.32-1.498 3.392-2.131 6.53-3.857 13.052-6.443 19.49-7.734 3.287-.66 6.552-.978 9.786-.959.559.003 1.117.012 1.673.036 7.843.327 15.514 2.274 21.602 5.481 5.941 3.13 10.924 5.438 15.213 7.146.213.085.424.167.634.249.084.033.17.068.254.1.83.322 1.631.618 2.41.895.679.241 1.345.471 1.988.681 8.394 2.74 13.814 2.88 19.182 2.837.42-.003 27.5-.012 27.5-.012v-6.68h-26.312ZM244.899 75.808h33.209v-6.723h-33.209M244.899 107.661h33.209v-6.723h-33.209"
                        className={styles.logoFillPrimary}
                        fillRule="nonzero"
                    />
                </g>
            </g>
        </g>
    </svg>

);

export default Logo;
