import React from 'react';
import LinkButton from '../components/buttons/LinkButton';
import { ButtonVariant } from '../components/buttons/Button';
import { useTranslation } from 'react-i18next';

const NotFoundPage: React.FC = () => {

    const { t } = useTranslation();

    return (
        <section>
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="font-header mb-4 text-7xl tracking-tight font-bold lg:text-9xl text-txt-lg-header dark:text-txt-dk-header">404</h1>
                    <p className="font-body font-bold mb-4 text-3xl tracking-tight text-txt-lg-placeholder md:text-4xl dark:text-txt-dk-placeholder">{ t("Something's missing.") } </p>
                    <p className="font-body font-normal mb-4 text-lg text-txt-lg-placeholde dark:text-txt-dk-placeholder">{t("Sorry, we can't find that page. You'll find lots to explore on the home page.")}</p>
                    <LinkButton to="/" text="Back to Homepage" variant={ButtonVariant.Default} />
                </div>
            </div>
        </section>
    );
};

export default NotFoundPage;