import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineFrown } from 'react-icons/ai'; // Import a sad smiley icon
import LinkButton from '../components/buttons/LinkButton';
import { ButtonVariant } from '../components/buttons/Button';

interface ErrorPageProps {
    errorCode?: number;
    errorMessage?: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ errorCode, errorMessage }) => {
    const { t } = useTranslation();

    return (
        <section className="flex items-center justify-center min-h-screen">
            <div className="text-center">
                <AiOutlineFrown className="mx-auto mb-4 text-enerando-red" size={100} />
                <h1 className="font-header mb-4 text-7xl tracking-tight font-bold lg:text-9xl text-txt-lg-header dark:text-txt-dk-header">
                    {errorCode || 'Error'}
                </h1>
                <p className="font-body font-bold mb-4 text-3xl tracking-tight text-txt-lg-placeholder md:text-4xl dark:text-txt-dk-placeholder">
                    {errorMessage || t("Something went wrong.")}
                </p>
                <p className="font-body font-normal mb-4 text-lg text-txt-lg-placeholder dark:text-txt-dk-placeholder">
                    {t("Sorry for the inconvenience. Please try again later or contact support if the problem persists.")}
                </p>
                <LinkButton to="/" text={t('Back to Homepage')} variant={ButtonVariant.Default} />
            </div>
        </section>
    );
};

export default ErrorPage;