import axios from 'axios';
import { UUID } from 'crypto';
import { Paths } from '../api/client';

export const API_BASE_URL = 'https://api.staging.enerando.systems';
// export const API_BASE_URL = 'http://localhost:8000';

const getToken = (): string | null => {
  return localStorage.getItem('accessToken');
};

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const getChargepoints = async (): Promise<Paths.SitesChargepointsList.Responses.$200> => {
  const response = await axiosInstance.get('/api/v2/sites/chargepoints');
  return response.data;
};

export const getChargepointDetails = async (id: UUID): Promise<Paths.SitesChargepointsRetrieve.Responses.$200> => {
  const response = await axiosInstance.get(`/api/v2/sites/chargepoints/${id}`);
  return response.data;
};

export const getSessions = async (): Promise<Paths.SessionList.Responses.$200> => {
  const response = await axiosInstance.get('/api/v2/sessions');
  return response.data;
};

export const getSessionDetails = async (id: UUID): Promise<Paths.SessionDetails.Responses.$200> => {
  const response = await axiosInstance.get(`/api/v2/sessions/${id}`);
  return response.data;
};

export const getSiteList = async (): Promise<Paths.SitesList.Responses.$200> => {
  const response = await axiosInstance.get(`/api/v2/sites`);
  return response.data;
}

export const getSite = async (id: UUID): Promise<Paths.SitesRetrieve.Responses.$200> => {
  const response = await axiosInstance.get(`/api/v2/sites/${id}`);
  return response.data;
}

export const postSiteUpdate = async (id: UUID): Promise<Paths.SitesUpdateCreate.Responses.$200> => {
  const response = await axiosInstance.post(`/api/v2/sites/update/${id}`);
  return response.data;
}

export const getCardsList = async (): Promise<Paths.CardsList.Responses.$200> => {
  const response = await axiosInstance.get(`/api/v2/cards`);
  return response.data;
}

export const getUserList = async (): Promise<Paths.UsersList.Responses.$200> => {
  const response = await axiosInstance.get(`/api/v2/users`);
  return response.data;
}

export const postStartStopSession = async (id: UUID, action: string): Promise<any> => {
  // change Charging to Session
  const response = await axiosInstance.post(`/api/v2/chargepoints/${id}/start_stop_charging`, { action });
  return response.data;
};

export const getCardDetails = async (id: UUID): Promise<Paths.CardsRetrieve.Responses.$200> => {
  const response = await axiosInstance.get(`/api/v2/cards/${id}`);
  return response.data;
}

export const getUserDetails = async (id: UUID): Promise<Paths.UsersRetrieve.Responses.$200> => {
  const response = await axiosInstance.get(`/api/v2/users/${id}`);
  return response.data;
}
