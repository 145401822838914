import React from 'react';

interface ContainerProps {
  title: string;
  description?: string;
  children: React.ReactNode;
  titlePosition?: 'left' | 'top';
  bgColor?: 'color1' | 'color2';
  className?: string;
  toggle?: React.ReactNode; // adds option to set toggle button (for site on off switch)
}

const Container: React.FC<ContainerProps> = ({
  title,
  description,
  children,
  titlePosition = 'top',
  bgColor = 'color1',
  className = '',
  toggle
}) => {
  const getBgClass = () => {
    if (bgColor === 'color1') {
      return 'bg-bg-lg-bg dark:bg-bg-dk-bg';
    } else if (bgColor === 'color2') {
      return 'bg-bg-lg-content dark:bg-bg-dk-content';
    }
  };

  return (
    <div className={`p-6 rounded-2xl shadow-sm ${getBgClass()} ${className}`}>
      <div className={`mb-4 flex ${titlePosition === 'top' ? 'justify-center' : 'justify-between'} items-center`}>
        <h2 className="text-lg font-body font-semibold uppercase text-txt-lg-header dark:text-txt-dk-header">{title}</h2>
        {toggle && <div className="ml-4">{toggle}</div>}
      </div>
      {description && <p className="text-sm text-gray-600 dark:text-gray-400">{description}</p>}
      <div className="mt-4">
        {children}
      </div>
    </div>
  );
};

export default Container;
