import React from 'react';

interface SpinnerProps {
    size?: number; // Optional size prop to adjust the spinner size
}

const Spinner: React.FC<SpinnerProps> = ({ size = 32 }) => {
    const sizeStyle = { width: `${size}px`, height: `${size}px` };

    return (
        <div
            role="status"
            className="inline-block animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-middle motion-reduce:animate-[spin_1.5s_linear_infinite] text-enerando-green"
            style={sizeStyle}
        >
            <span className="sr-only">Loading...</span>
        </div>
    );
};

export default Spinner;