import React from 'react';
import { FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import Dropdown from '../basics/DropDown';
import { useAuth } from '../../contexts/AuthContext';

interface UserMenuProps {
    isAuthenticated: boolean;
    onLogout: () => void;
}

export type UserMenuItem = {
    label: string,
    link: string,
    type?: string,
}

const UserMenu: React.FC<UserMenuProps> = ({ isAuthenticated, onLogout }) => {
    const { user } = useAuth();

    const userMenuItems = [
        // { label: 'Dashboard', link: '#' },
        // { label: 'Settings', link: '#' },
        { label: 'Log out', link: '#', type: 'danger', action: onLogout },
    ];

    return (
        isAuthenticated ? (
            <div className="flex items-center ms-3">
                <Dropdown
                    trigger={
                        <button
                            type="button"
                            className="flex items-center justify-center w-10 h-10 rounded-2xl"
                        >
                            <FaUser className="w-5 h-5 text-icon-lg dark:text-icon-dk hover:text-enerando-green" aria-hidden="true" />
                            <span className="sr-only">Open user menu</span>
                        </button>
                    }
                >
                    <div className="p-2">
                        <div className="pb-2">
                            <p className="text-sm font-medium text-txt-lg-body truncate dark:text-txt-dk-body">{user?.email}</p>
                        </div>
                        <ul className="py-1 text-sm text-txt-lg-body dark:text-txt-dk-body">
                            {userMenuItems.map((item) => (
                                <li key={item.label}>
                                    <a
                                        href={item.link}
                                        onClick={item.action}
                                        className={`flex items-center p-2 rounded-2xl ${item.type === 'danger'
                                            ? 'text-enerando-red dark:text-white hover:bg-enerando-red dark:hover:opacity-50 dark:bg-enerando-red hover:text-white'
                                            : 'text-txt-lg-header dark:text-txt-dk-header hover:bg-hover-lg dark:hover:bg-hover-dk'
                                            }`}
                                    >
                                        {item.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Dropdown>
            </div>
        ) : (
            <Link to="/login" className="font-body font-normal text-txt-lg-header dark:text-txt-dk-header mx-2 hover:text-enerando-green">
                Login
            </Link>
        )
    );
};

export default UserMenu;
