import React from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { UUID } from 'crypto';
import { useTranslation } from 'react-i18next';

import useSite from '../hooks/useSite';
import useSiteUpdate from '../hooks/useSiteUpdate';
import Spinner from '../components/Spinner';

import CardGrid, { CardItem, HoverEffect, Alignment, CardType } from './positioning/CardGrid';
import ToggleButton from './buttons/ToggleButton';

interface SiteProps {
    siteId: UUID;
}

const SiteComponent: React.FC<SiteProps> = ({ siteId }) => {
    const { site, loading, error, refetch } = useSite(siteId);
    const { updateSite, loading: updateLoading } = useSiteUpdate();
    const { t } = useTranslation();

    const handleToggleChange = async () => {
        if (site) {
            await updateSite(siteId);
            await refetch();
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <Spinner size={32} />
            </div>
        );
    }

    if (error) {
        return <div>Error loading site: {error}</div>;
    }

    if (!site) {
        return <div>Site not found</div>;
    }

    // Flatten chargepoints across all pools and fuses
    const chargepoints = site.pools?.flatMap((pool) =>
        pool.fuses.flatMap((fuse) => fuse.chargepoints)
    ) || [];

    const cardsChargepoints: CardItem[] = chargepoints.map((chargepoint) => ({
        type: CardType.Default,
        title: chargepoint.desc,
        href: `/infrastructure/${chargepoint.uuid}`,
        loading: loading,
        titleFontSize: 'text-sm',
        alignment: Alignment.Center,
        fullWidth: true,
        hoverEffect: HoverEffect.Card,
        status: chargepoint.status ?? 'UNKNOWN'
    })).sort((a, b) => (a.title ?? '').localeCompare(b.title ?? ''));

    const renderStatusList = (fields: { label: string, value: boolean | undefined }[]) => (
        <li className="mb-2">
            {fields.map((field, index) => (
                <span key={field.label} className="font-body text-sm">
                    {t(field.label)}:
                    <span className={field.value ? 'text-enerando-green' : 'text-enerando-red'}>
                        {field.value ? t(' Enabled') : t(' Disabled')}
                    </span>
                    {index < fields.length - 1 && ' | '}
                </span>
            ))}
        </li>
    );

    const pvSurplusChargingFields = [
        { label: 'PV Surplus Charging', value: site.pv_surplus_charging_enabled },
        { label: 'Always Receive Minimum', value: site.pv_surplus_charging_always_receive_minimum }
    ];

    const pvChargingFields = [
        { label: 'PV Charging', value: site.pv_charging_enabled },
        { label: 'Always Receive Minimum', value: site.pv_charging_always_receive_minimum }
    ];

    const peakLoadShavingFields = [
        { label: 'Peak Load Shaving', value: site.peak_load_shaving_enabled },
        { label: 'Always Receive Minimum', value: site.peak_load_shaving_always_receive_minimum }
    ];

    return (
        <div>
            <ToggleButton
                checked={!site.disabled}
                onChange={handleToggleChange}
                loading={updateLoading}
            />
            <ul className="list-disc pl-6 leading-tight space-y-1">
                {renderStatusList(pvSurplusChargingFields)}
                {renderStatusList(pvChargingFields)}
                {renderStatusList(peakLoadShavingFields)}
            </ul>

            <div className="mt-8 space-y6">
                <h2 className="text-lg font-body font-semibold uppercase text-txt-lg-header dark:text-txt-dk-header">chargepoints</h2>
            </div>

            <div className="mb-4 flex mt-2">
                {cardsChargepoints.length > 0 ? (
                    <CardGrid items={cardsChargepoints} loading={loading} />
                ) : (
                    <div>{t('No chargepoints available')}</div>
                )}
            </div>


        </div>
    );
};

export default SiteComponent;
