import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UUID } from "crypto";
import { useNavigate } from 'react-router-dom';

import useUserDetails from "../hooks/useUserDetails";

import Heading from '../components/typography/Heading';
import Table, { TableColumn, TableRow } from '../components/basics/table';
import CardGrid, { CardItem, CardType } from "../components/positioning/CardGrid";

// icons
import { FaUserFriends } from "react-icons/fa";
import ErrorPage from "./ErrorPage";
import { FaCheckCircle } from "react-icons/fa";
import { HiOfficeBuilding } from "react-icons/hi";
import { IoIosCloseCircle } from "react-icons/io";


const UserDetailPage: React.FC = () => {
    const { id } = useParams<{ id: UUID }>();
    const { userDetails, cards, sessions, error, loading } = useUserDetails(id!);
    const { t } = useTranslation();
    const navigate = useNavigate();

    if (error) {
        return <ErrorPage errorCode={500} errorMessage={error} />;
    }

    const user = userDetails;
    const sessionlist = sessions ? sessions : [];
    const cardlist = cards ? cards : [];

    const sessionColumns: TableColumn[] = [
        // adjust header
        { header: t("date"), key: "date" },
        { header: t("address"), key: "address" },
        { header: t("charged in kwh"), key: "charged" },
    ]

    const sessionRows: TableRow[] = sessionlist.map(session => ({
        date: `${new Date(session.start).toLocaleString()} - ${new Date(session.stop).toLocaleString()}`,
        address: session.address,
        charged: (session.energy / 1000).toFixed(2),
        uuid: session.uuid,
    }));

    const cardColumns: TableColumn[] = [
        // adjust header
        { header: t("card number"), key: "number" },
        { header: t("status"), key: "enabled" },
    ]

    const cardRows: TableRow[] = cardlist.map(card => ({
        number: card.number,
        enabled: card.enabled ? "Enabled" : "Disabled",
        uuid: card.uuid ?? "",
    }));

    const userCards: CardItem[] = [
        {
            type: CardType.Attribute,
            name: t('email'),
            value: user?.email,
            loading: loading,
            icon: <FaUserFriends className={`text-lg text-enerando-green`} />,
        },
        {
            type: CardType.Attribute,
            name: t('priority'),
            value: (user?.prio) ? t("Yes") : t("No"),
            loading: loading,
            icon: user?.prio ? <FaCheckCircle className={`text-lg text-enerando-green`} /> : <IoIosCloseCircle className={`text-lg text-enerando-red`} />,
        },
        {
            type: CardType.Attribute,
            name: t('company'),
            value: user?.company || "N/A",
            loading: loading,
            icon: <HiOfficeBuilding className={`text-lg text-enerando-green`} />,
        }
        // add more fields here
    ];

    // maybe sugar syntax here
    const handleRowClick = (row: TableRow, key: string) => {
        if (row.uuid !== "") {  // Check if uuid is defined
            if (key === "sessions") {
                navigate(`/sessions/${row.uuid}`);
            } else if (key === "cards") {
                navigate(`/cards/${row.uuid}`);
            }
        }
    };

    return (
        <div>
            <Heading
                title={t('Details')}
                highlight={t('User')}
                description={t('All details about this user.')}
            />
            <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">
                <CardGrid items={userCards} loading={loading} />

                <div className="mt-20">
                    <h2 className="mb-2 text-lg font-body font-semibold uppercase text-txt-lg-header dark:text-txt-dk-header">{t('sessions')}</h2>
                    <Table table_name={"sessions_" + user?.email} columns={sessionColumns} data={sessionRows} onRowClick={(row) => handleRowClick(row, 'sessions')} loading={loading} />
                </div>
                <div className="mt-20">
                    <h2 className="mb-2 text-lg font-body font-semibold uppercase text-txt-lg-header dark:text-txt-dk-header">{t('cards')}</h2>
                    <Table table_name={"cards_" + user?.email} columns={cardColumns} data={cardRows} onRowClick={(row) => handleRowClick(row, 'cards')} loading={loading} />
                </div>
            </div>

        </div>
    )

};

export default UserDetailPage;