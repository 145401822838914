import React from 'react';
import { MenuItem } from './types';
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useTranslation } from 'react-i18next';

interface SidebarProps {
    menuItems: MenuItem[];
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>; // Added setSidebarOpen
    loading: boolean;
    navbarHeight: number;
    gapSize: number;
    windowWidth: number; // Added windowWidth
}

const Sidebar: React.FC<SidebarProps> = ({
    menuItems,
    sidebarOpen,
    setSidebarOpen,
    loading,
    navbarHeight,
    gapSize,
    windowWidth,
}) => {
    const { t } = useTranslation();

    const sidebarWidth = 256; // Original sidebar width in pixels
    const adjustedWidth = sidebarWidth - gapSize * 2; // Adjusted width
    const totalSidebarWidth = adjustedWidth + gapSize; // Total width including left gap

    // Determine if the sidebar is in overlay mode
    const isOverlay = windowWidth < 1024;

    return (
        <aside
            className={`fixed z-40 bg-bg-lg-content dark:bg-bg-dk-content rounded-2xl`}
            style={{
                top: navbarHeight + gapSize * 2,
                bottom: gapSize,
                left: gapSize,
                width: `${adjustedWidth}px`,
                height: `calc(100vh - ${navbarHeight + gapSize * 3}px)`,
                transform: sidebarOpen
                    ? 'translateX(0)'
                    : `translateX(-${totalSidebarWidth}px)`,
                transition: 'transform 0.3s ease-in-out',
            }}
            aria-label="Sidebar"
        >
            <div className="h-full flex flex-col justify-between bg-bg-lg-content dark:bg-bg-dk-content rounded-2xl">
                {/* Sidebar content */}
                <div className="px-3 pt-5 pb-5 overflow-y-auto flex-grow">
                    <ul className="space-y-2 font-body">
                        {loading ? (
                            // Display skeletons while loading
                            Array(5)
                                .fill(0)
                                .map((_, index) => (
                                    <li key={index}>
                                        <Skeleton height={40} width="100%" />
                                    </li>
                                ))
                        ) : (
                            menuItems.map((item, index) => (
                                <li key={index}>
                                    <NavLink
                                        to={item.link}
                                        className={({ isActive }) =>
                                            `flex items-center p-2 rounded-2xl group ${isActive
                                                ? 'text-enerando-blue dark:text-txt-dk-body font-semibold'
                                                : 'text-enerando-gray dark:text-txt-dk-placeholder font-normal'
                                            } font-body hover:bg-hover-lg dark:hover:bg-hover-dk`
                                        }
                                        onClick={() => {
                                            // Close the sidebar if in overlay mode
                                            if (isOverlay) {
                                                setSidebarOpen(false);
                                            }
                                        }}
                                    >
                                        {item.icon}
                                        <span className="ml-3">{item.label}</span>
                                    </NavLink>
                                </li>
                            ))
                        )}
                    </ul>
                </div>
                <footer className="p-4 bg-bg-lg-content border-t border-border-lg dark:bg-bg-dk-content dark:border-border-dk rounded-b-2xl">
                    <div className="flex flex-col items-center justify-center">
                        <a href="https://www.enerando.energy/" className="text-center text-sm text-gray-500 dark:text-gray-400 hover:underline">
                            © {new Date().getFullYear()} ENERANDO <br /> Technologies GmbH
                        </a>
                        <ul className="flex justify-center items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
                            <li className="mx-2">
                                <a href="https://www.enerando.energy/rechtliches-impressum/datenschutz#impressum" className="hover:underline">{t("legal notice")}</a>
                            </li>
                            <li className="mx-2">
                                <a href="https://www.enerando.energy/rechtliches-impressum/datenschutz" className="hover:underline">{t("privacy policy")}</a>
                            </li>
                        </ul>
                    </div>
                </footer>

            </div>
        </aside>
    );
};

export default Sidebar;
