import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UUID } from "crypto";
import { useNavigate } from 'react-router-dom';
import ErrorPage from "./ErrorPage";

import useCardDetails from "../hooks/useCardDetails";

import Heading from '../components/typography/Heading';
import Table, { TableColumn, TableRow } from '../components/basics/table';
import CardGrid, { CardItem, CardType } from "../components/positioning/CardGrid";

// icons
import { AiOutlineNumber } from "react-icons/ai";
import { FaCheckCircle } from 'react-icons/fa'; // change this
import { FaUserFriends } from "react-icons/fa";
import { MdSensorOccupied } from "react-icons/md"; // tenant
import { MdDateRange } from "react-icons/md"; // created
import { HiOfficeBuilding } from "react-icons/hi";


const CardDetailPage: React.FC = () => {
    const { id } = useParams<{ id: UUID }>();
    const { cardDetails, sessions, error, loading } = useCardDetails(id!);
    const { t } = useTranslation();
    const navigate = useNavigate();

    if (error) {
        return <ErrorPage errorCode={500} errorMessage={error} />;
    }


    const card = cardDetails;
    // ToDo: handle what happens if no session data (maybe empty table representation)
    const sessionsList = sessions ? sessions : [];

    const tableColumns: TableColumn[] = [
        { header: t("date"), key: "date" },
        { header: t("user"), key: "user" },
        { header: t("charged in kwh"), key: "charged" },
    ]

    const tableRows: TableRow[] = sessionsList.map(session => ({
        date: `${new Date(session.start).toLocaleString()} - ${new Date(session.stop).toLocaleString()}`,
        user: card?.user.email || 'N/A', // ToDo: add user to the table (owner of card)
        charged: (session.energy / 1000).toFixed(2), // where to do conversion?
        uuid: session.uuid,
    }));

    const cardCards: CardItem[] = [
        {
            type: CardType.Attribute,
            name: t('card number'),
            value: card?.number,
            loading: loading,
            icon: <AiOutlineNumber className={`text-lg text-enerando-green`} />,
        },
        {
            type: CardType.Attribute,
            name: t('status'),
            value: `${card?.enabled ? t('Enabled') : t('Disabled')}`,
            loading: loading,
            icon: <FaCheckCircle className={`text-lg ${card?.enabled ? 'text-enerando-green' : 'text-danger-base'}`} />,
        },
        {
            type: CardType.Attribute,
            name: t('user'),
            value: card?.user.email,
            loading: loading,
            icon: <FaUserFriends className={`text-lg text-enerando-green`} />,
        },
        // {
        //     type: CardType.Attribute,
        //     name: t('tenant'),
        //     value: card?.user.tenant.name,
        //     loading: loading,
        //     icon: <MdSensorOccupied className={`text-lg text-enerando-green`} />,
        // },
        {
            type: CardType.Attribute,
            name: t('created'),
            value: card?.created ? new Date(card?.created).toLocaleString() : 'N/A',
            loading: loading,
            icon: <MdDateRange className={`text-lg text-enerando-green`} />,

        },
        {
            type: CardType.Attribute,
            name: t('company'),
            value: card?.user.company || 'N/A',
            loading: loading,
            icon: <HiOfficeBuilding className={`text-lg text-enerando-green`} />
        }
    ]

    const handleRowClick = (row: TableRow) => {
        navigate(`/sessions/${row.uuid}`);
    };

    return (
        <div>
            <Heading
                title={t('Details')}
                highlight={t('Card')}
                description={t('All details about this card.')}
            />

            <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">
                <CardGrid items={cardCards} loading={loading} />
                <div className="mt-20">
                    <h2 className="mb-2 text-lg font-body font-semibold uppercase text-txt-lg-header dark:text-txt-dk-header">{t('sessions')}</h2>
                    <Table table_name={t('sessions') + '-' + card?.number} columns={tableColumns} data={tableRows} onRowClick={handleRowClick} loading={loading} />
                </div>
            </div>
        </div>
    );
};

export default CardDetailPage;