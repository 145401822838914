import { useState, useEffect } from "react";
import { getSiteList } from "../services/apiService";
import { Components } from "../api/client";

type Site = Components.Schemas.Site;

// Issue: This is loading an entire site object right now but doesn't need to

const useSites = () => {
    const [sites, setSites] = useState<Pick<Site, 'uuid' | 'name'>[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data: Site[] = await getSiteList();
                const minimalData = data.map(site => ({
                    uuid: site.uuid,
                    name: site.name,
                }));
                setSites(minimalData);
            } catch (error) {
                setError('Failed to fetch sites.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { sites, error, loading };
};

export default useSites;