import { useState, useEffect } from 'react';
import { getUserDetails } from '../services/apiService';
import { Components } from '../api/client';
import { UUID } from 'crypto';

type User = Components.Schemas.User;
type Card = Components.Schemas.Card;
type Session = Components.Schemas.Session;

const useUserDetails = (id: UUID) => {
    const [userDetails, setUserDetails] = useState<User>();
    const [cards, setCards] = useState<Card[]>();
    const [sessions, setSessions] = useState<Session[]>();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getUserDetails(id);
                setUserDetails(data.detail_user);
                setCards(data.cards); // porblem in api doc
                setSessions(data.sessions);
            } catch (error) {
                setError('Failed to fetch user details.');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);

    return { userDetails, cards, sessions, error, loading }
};

export default useUserDetails;